.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/*=======================================================
    A stylesheet by:
    Leon Taveras (LeonimuZ) || http://be.net/lnz
 
    Includes styles from:
    YUI Pure, H5bp, Foundation, CSS-Tricks.com
    & SachaGreif.com
=======================================================*/

body {
	background: #E4E4E4 url(https://raw.github.com/subtlepatterns/SubtlePatterns/master/cardboard_flat.png) repeat;
}

#content {
	margin: 5px auto;
	width: 960px;
	text-align: center;
}

h1 {
	font-family: "Oswald", sans-serif;
}

.sub {
	letter-spacing: -1px;
	text-shadow: 0 2px 0 white;
}

.tuber {
	position: relative;
	padding: 0 10px;
	background: -webkit-linear-gradient(bottom, rgb(109, 2, 2) 22%, rgb(201, 0, 0) 61%);
	color: #fff;
	letter-spacing: -2px;
	text-shadow: 0 3px 0 #000;
	border-radius: 50% / 18%;
	box-shadow: 0px 15px 20px -5px rgba(0, 0, 0, 0.67);
}

